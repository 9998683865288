import React from 'react';
import { Typography, Button, makeStyles, Card, CardContent, CardMedia } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(6, 0),
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: theme.spacing(2),
    maxWidth: 1200,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  card: {
    flex: 1,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  mediaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: theme.spacing(0),
  },
  media: {
    maxHeight: 500,
    maxWidth: '100%',
    borderRadius: theme.shape.borderRadius,
  },
  content: {
    padding: theme.spacing(4),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  heading: {
    color: '#333',
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  paragraph: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    alignSelf: 'center',
  },
}));

interface InfoCardProps {
  children: React.ReactNode;
}

const InfoCard: React.FC<InfoCardProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        {children}
      </CardContent>
    </Card>
  );
};

interface MediaCardProps {
  image: string;
  title: string;
}

const MediaCard: React.FC<MediaCardProps> = ({ image, title }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.mediaContainer}>
        <CardMedia
          component="img"
          className={classes.media}
          image={image}
          title={title}
        />
      </CardContent>
    </Card>
  );
};

const KnaufInfoSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.cardContainer}>
        <MediaCard image="/Buildonus.png" title="Build On Us" />
        <InfoCard>
          <Typography variant="h4" component="h2" className={classes.heading}>
            Welcome to Knauf Digital's IDP
          </Typography>
          <Typography variant="body1" paragraph className={classes.paragraph}>
            Thrilled to have you here! The goal is to make your development journey smoother and more enjoyable.
          </Typography>
          <Typography variant="body1" paragraph className={classes.paragraph}>
            Built on Kubernetes, the platform lets you easily onboard, deploy, and manage your applications. No need to stress about the technical details of infrastructure.
          </Typography>
          <Typography variant="body1" paragraph className={classes.paragraph}>
            With an intuitive interface, deploying applications is just a few clicks away. This means more time for coding and innovation, and less time troubleshooting.
          </Typography>
          <Typography variant="body1" paragraph className={classes.paragraph}>
            The aim is to make deployment smooth and hassle-free. Let's build something amazing together!
          </Typography>
          <Button
            component={Link}
            to="/docs/default/component/cst-knauf-idp"
            variant="contained"
            size="large"
            className={classes.button}
          >
            Learn More
          </Button>
        </InfoCard>
      </div>
    </div>
  );
};

export default KnaufInfoSection;