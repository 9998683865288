import { Entity } from '@backstage/catalog-model';
import {
  createPlugin,
  createRoutableExtension,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import {
  AzureContainerRegistryApiClient,
  AzureContainerRegistryApiRef,
} from './api';
import { AZURE_CONTAINER_REGISTRY_ANNOTATION_IMAGE_NAME, AZURE_CONTAINER_REGISTRY_ANNOTATION_NAMES } from './consts';
import { rootRouteRef } from './routes';

export const knaufAcrPlugin = createPlugin({
  id: 'knauf-acr',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: AzureContainerRegistryApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, configApi, identityApi }) =>
        new AzureContainerRegistryApiClient({
          discoveryApi,
          configApi,
          identityApi,
        }),
    }),
  ],
});

export const KnaufAcrPage = knaufAcrPlugin.provide(
  createRoutableExtension({
    name: 'KnaufAcrPage',
    component: () =>
      import('./components/AcrDashboardPage').then(m => m.AcrDashboardPage),
    mountPoint: rootRouteRef,
  }),
);

export const isAcrAvailable = (entity: Entity) =>
  Boolean(
    entity?.metadata.annotations?.[AZURE_CONTAINER_REGISTRY_ANNOTATION_IMAGE_NAME] &&
    entity?.metadata.annotations?.[AZURE_CONTAINER_REGISTRY_ANNOTATION_NAMES],
  );
