import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { CustomMissingAnnotation } from './CustomMissingAnnotation';
import { AZURE_CONTAINER_REGISTRY_ANNOTATION_IMAGE_NAME, AZURE_CONTAINER_REGISTRY_ANNOTATION_NAMES } from '../../consts';

type AnnotationCheckProps = {
  children: React.ReactNode;
};

export const AnnotationCheck = ({ children }: AnnotationCheckProps) => {
  const { entity } = useEntity();

  const imageName = entity?.metadata?.annotations?.[AZURE_CONTAINER_REGISTRY_ANNOTATION_IMAGE_NAME];
  const registryNames = entity?.metadata?.annotations?.[AZURE_CONTAINER_REGISTRY_ANNOTATION_NAMES];

  if (!imageName || !registryNames) {
    return (
      <CustomMissingAnnotation
        annotations={[
          AZURE_CONTAINER_REGISTRY_ANNOTATION_NAMES,
          AZURE_CONTAINER_REGISTRY_ANNOTATION_IMAGE_NAME,
        ]}
      />
    );
  }

  return <>{children}</>;
};