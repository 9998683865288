import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { identityApiRef } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { Progress, ErrorPanel } from '@backstage/core-components';
import {
  Card, CardContent, Typography, Grid, makeStyles, CardActionArea
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  card: {
    height: '160px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    boxShadow: theme.shadows[4],
    transition: 'transform 0.3s, box-shadow 0.3s',
    borderRadius: '1px',
    background: theme.palette.primary.main,
  },
  content: {
    textAlign: 'center',
  },
  cardContainer: { marginTop: theme.spacing(2) },
}));

const groupEntitiesByKind = (entities: Entity[]) => {
  return entities.reduce((acc, entity) => {
    const kind = entity.kind;
    if (!acc[kind]) {
      acc[kind] = [];
    }
    acc[kind].push(entity);
    return acc;
  }, {} as Record<string, Entity[]>);
};

const ResourceCard = ({ kind, count, onClick }: { kind: string, count: number, onClick: () => void }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Card className={classes.card}>
        <CardActionArea onClick={onClick}>
          <CardContent className={classes.content}>
            <Typography variant="h5">{kind}</Typography>
            <Typography variant="body2">{count} resources</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const UserOwnedResources = () => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [entities, setEntities] = useState<Entity[]>([]);
  const [userGroup, setUserGroup] = useState<string>('');

  useEffect(() => {
    const fetchUserGroup = async () => {
      try {
        const identity = await identityApi.getBackstageIdentity();
        const userGroups = identity.ownershipEntityRefs;
        setUserGroup(userGroups.find(groupRef => groupRef.startsWith('group:default/')) ?? '');
      } catch (err) {
        setError(err as Error);
      }
    };

    fetchUserGroup();
  }, [identityApi]);

  useEffect(() => {
    const fetchEntities = async () => {
      if (!userGroup) {
        setLoading(false);
        return;
      }
      try {
        const response = await catalogApi.getEntities({
          filter: { 'spec.owner': userGroup },
        });
        setEntities(response.items);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchEntities();
  }, [catalogApi, userGroup]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <ErrorPanel error={error} />;
  }

  const groupedEntities = groupEntitiesByKind(entities);
  const entityKinds = Object.keys(groupedEntities);

  const handleCardClick = (kind: string) => {
    navigate(`/catalog?filters[kind]=${kind}&filters[user]=owned`);
  };

  return (
    <Grid container spacing={3} className={classes.cardContainer}>
      {entityKinds.length > 0 ? (
        entityKinds.map(kind => (
          <ResourceCard
            key={kind}
            kind={kind}
            count={groupedEntities[kind].length}
            onClick={() => handleCardClick(kind)}
          />
        ))
      ) : (
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <Typography variant="h5">No Resources</Typography>
              <Typography variant="body2">Your team does not own any resources.</Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default UserOwnedResources;