import React from 'react';
import { Card, CardContent, Typography, Grid, makeStyles } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import GroupIcon from '@material-ui/icons/Group';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import DescriptionIcon from '@material-ui/icons/Description';
import NotificationsIcon from '@material-ui/icons/Notifications';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SchoolIcon from '@material-ui/icons/School';
import CreateIcon from '@material-ui/icons/AddBox';
import TaskIcon from '@material-ui/icons/Assignment';
import ToolboxIcon from '@material-ui/icons/Build';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary, // Use primary text color
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main, // Keep icons blue
  },
}));

const tools = [
  {
    url: '/catalog?filters%5Bkind%5D=component&filters%5Btype%5D=service&filters%5Buser%5D=all',
    label: 'Services',
    description: 'Browse all available services within the organization. This includes backend services, APIs, and other service components.',
    icon: <SettingsIcon />,
  },
  {
    url: '/catalog?filters%5Bkind%5D=system&filters%5Buser%5D=all',
    label: 'Systems',
    description: 'View all systems within the organization. Systems are collections of services and components that work together to provide a larger functionality.',
    icon: <AccountTreeIcon />,
  },
  {
    url: '/catalog?filters%5Bkind%5D=user',
    label: 'Users',
    description: 'Browse the list of all users within the organization. This includes their profiles and the services they are associated with.',
    icon: <PersonIcon />,
  },
  {
    url: '/catalog?filters%5Bkind%5D=group',
    label: 'Groups',
    description: 'View all groups within the organization. Groups are collections of users that share common responsibilities or roles.',
    icon: <GroupIcon />,
  },
  {
    url: '/tech-radar',
    label: 'Company Radar',
    description: 'View the company tech radar. This provides an overview of the technologies being used, their adoption status, and future plans.',
    icon: <TrackChangesIcon />,
  },
  {
    url: '/docs',
    label: 'Documentation',
    description: 'Access documentation for various projects and services. This includes technical documentation, user guides, and API references.',
    icon: <DescriptionIcon />,
  },
  {
    url: '/explore/groups',
    label: 'Organization Chart',
    description: 'View the organization chart. This provides a hierarchical view of the organization, including departments, teams, and their members.',
    icon: <AccountTreeIcon />,
  },
  {
    url: '/notifications',
    label: 'Notifications',
    description: 'Manage your notifications and alerts. Stay updated with the latest changes and updates within the organization.',
    icon: <NotificationsIcon />,
  },
  {
    url: '/settings',
    label: 'Profile Settings',
    description: 'Configure your personal settings and preferences. Customize your experience within the platform.',
    icon: <SettingsIcon />,
  },
  {
    url: '/infrawallet',
    label: 'Infrastructure Cost',
    description: 'Manage your infrastructure wallet. View and control your cloud resources and spending.',
    icon: <WalletIcon />,
  },
  {
    url: '/learning-paths',
    label: 'Learning Paths',
    description: 'Explore learning paths and resources. Enhance your skills and knowledge with curated learning materials.',
    icon: <SchoolIcon />,
  },
  {
    url: '/create',
    label: 'Self-Service',
    description: 'Start new projects and create new components. Use templates to quickly set up new services and applications.',
    icon: <CreateIcon />,
  },
  {
    url: '/create/tasks',
    label: 'Tasks',
    description: 'Manage your tasks and to-dos. Keep track of your work and stay organized.',
    icon: <TaskIcon />,
  },
  {
    url: '/toolbox',
    label: 'Toolbox',
    description: 'Access a collection of useful tools and utilities. Enhance your productivity with these handy tools.',
    icon: <ToolboxIcon />,
  }
];

const ToolkitCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title}>Platform Links</Typography>
        <Grid container spacing={2}>
          {tools.map(tool => (
            <Grid item xs={12} sm={6} md={4} key={tool.label}>
              <Grid container alignItems="center">
                <Grid item className={classes.icon}>
                  {tool.icon}
                </Grid>
                <Grid item>
                  <Link to={tool.url} className={classes.link}>
                    <Typography variant="body1">{tool.label}</Typography>
                  </Link>
                  <Typography variant="body2" color="textSecondary">
                    {tool.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ToolkitCard;