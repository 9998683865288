import React, { useState } from 'react';
import { Paper, Typography, Button, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  supportCard: {
    marginTop: theme.spacing(2), // Move card down
    padding: theme.spacing(2), // Adjust padding
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    minWidth: '200px', // Ensure the card has a minimum width
    maxWidth: '250px', // Ensure the card has a maximum width
    position: 'relative', // Ensure the close button is positioned correctly
  },
  supportButton: {
    marginTop: theme.spacing(1), // Increase margin to separate button from text
    backgroundColor: '#0077C8', // Updated blue color
    color: '#fff',
    fontSize: '0.75rem', // Smaller font size for button
    '&:hover': {
      backgroundColor: '#005fa3', // Slightly darker shade on hover
    },
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(0.5), // Smaller padding for close button
  },
  text: {
    marginTop: theme.spacing(2), // Move text down
    fontSize: '0.875rem', // Smaller font size for text
  },
  supportCardContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

const SupportCard = () => {
  const classes = useStyles();
  const [showSupportCard, setShowSupportCard] = useState(true);

  if (!showSupportCard) {
    return null;
  }

  return (
    <div className={classes.supportCardContainer}>
      <Paper className={classes.supportCard}>
        <IconButton className={classes.closeButton} onClick={() => setShowSupportCard(false)}>
          <CloseIcon />
        </IconButton>
        <Typography variant="body2" className={classes.text}>
          Need help, have an issue to report or have suggestions how to improve IDP?
        </Typography>
        <Button
          href="https://github.com/Knauf-Group/cst-knauf-idp/issues"
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          className={classes.supportButton}
        >
          Click here
        </Button>
      </Paper>
    </div>
  );
};

export default SupportCard;