import {
  createUnifiedTheme,
  createBaseThemeOptions,
  palettes,
} from '@backstage/theme';

export const KnaufLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      // Add custom colors here
      background: {
        default: '#f7f7f7',
        paper: '#fff',
      },
      primary: {
        main: '#0077c8',
        contrastText: '#fff',
      },
      navigation: {
        background: 'linear-gradient(135deg, #2c2c2c, #000000)',
        indicator: '#fff',
        color: '#b5b5b5',
        selectedColor: '#fff',
        submenu: {
          background: 'linear-gradient(135deg, #2c2c2c, #000000)',
        },
      },
    },
  }),
  components: {
    // Add custom components here
    BackstageSidebar: {
      styleOverrides: {
        drawer: ({ theme }) => ({
          background: 'linear-gradient(135deg, #2c2c2c, #000000)',
          width: '100%',
          display: 'flex',
          color: '#fff',
          boxShadow: theme.shadows[4],
          transition: 'transform 0.3s, box-shadow 0.3s',
          borderRadius: '1px',
          submenu: {
            background: 'linear-gradient(135deg, #2c2c2c, #000000)',
          }
        }),
      },
    },
    BackstageSidebarSubmenu: {
      styleOverrides: {
        title: {
          color: '#fff',
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            borderLeft: '4px solid',
            borderImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%) 1',
          },
          '&.Mui-selected': {
            borderLeft: '4px solid',
            borderImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%) 1',
          },
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(135deg, #2c2c2c, #000000)',
          color: '#fff',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '&:hover': {
            borderTop: '4px solid',
            borderImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%) 1',
          },
          '&.Mui-selected': {
            borderTop: '4px solid',
            borderImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%) 1',
          },
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          padding: theme.spacing(3),
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          backgroundImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%)',
          color: theme.palette.primary.contrastText,
          borderRadius: '1px',
        }),
      },
    },
    BackstageLogViewer: {
      styleOverrides: {
        log: {
          backgroundColor: '#282c34',
          color: '#abb2bf',
        },
        header: {
          backgroundColor: '#fff',
        }
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          backgroundImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%) !important',
          boxShadow: 'unset',
          color: '#fff',
        },
      },
    },
  },
});