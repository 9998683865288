import React from 'react';
import { Card, CardContent, Typography, Grid, makeStyles } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import SecurityIcon from '@material-ui/icons/Security';
import CodeIcon from '@material-ui/icons/Code';
import CloudIcon from '@material-ui/icons/Cloud';
import DescriptionIcon from '@material-ui/icons/Description';
import WorkIcon from '@material-ui/icons/Work';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GitHubIcon from '@material-ui/icons/GitHub';
import BusinessIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import WebIcon from '@material-ui/icons/Web';
import StorageIcon from '@material-ui/icons/Storage';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import SyncIcon from '@material-ui/icons/Sync';
import ContainerIcon from '@material-ui/icons/AllInbox';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary, // Use primary text color
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main, // Keep icons blue
  },
}));

const links = [
  {
    title: 'Snyk',
    description: 'Snyk helps you find, fix and monitor for known vulnerabilities in Node.js npm, JavaScript dependencies.',
    url: 'https://app.snyk.io/',
    icon: <SecurityIcon />,
  },
  {
    title: 'SonarCloud',
    description: 'SonarCloud is a cloud service offered by SonarSource.',
    url: 'https://sonarcloud.io/',
    icon: <CodeIcon />,
  },
  {
    title: 'DataDog',
    description: 'Datadog is a monitoring service for cloud-scale applications, providing monitoring of servers, databases, tools, and services.',
    url: 'https://app.datadoghq.eu/',
    icon: <CloudIcon />,
  },
  {
    title: 'Contentful',
    description: 'Contentful is a content management system (CMS) that enables cross-platform, multi-device online publishing.',
    url: 'https://app.contentful.com/',
    icon: <DescriptionIcon />,
  },
  {
    title: 'Jira',
    description: 'Jira Software is built for every member of your software team to plan, track, and release great software.',
    url: 'https://knaufjira.atlassian.net/jira/your-work',
    icon: <WorkIcon />,
  },
  {
    title: 'Confluence',
    description: 'Confluence is a team workspace where knowledge and collaboration meet.',
    url: 'https://knaufjira.atlassian.net/wiki/home',
    icon: <GroupWorkIcon />,
  },
  {
    title: 'ServiceNow',
    description: 'ServiceNow is a cloud computing platform that helps companies manage digital workflows for enterprise operations.',
    url: 'https://knauf.service-now.com/',
    icon: <BusinessIcon />,
  },
  {
    title: 'GitHub',
    description: 'GitHub is a development platform inspired by the way you work. From open source to business, you can host and review code, manage projects.',
    url: 'https://github.com/Knauf-Group',
    icon: <GitHubIcon />,
  },
  {
    title: 'Deskbird',
    description: 'Deskbird is a platform for booking and managing office space.',
    url: 'https://app.deskbird.com/',
    icon: <DashboardIcon />,
  },
  {
    title: 'Miro',
    description: 'Miro is an online collaborative whiteboard platform.',
    url: 'https://miro.com/app/dashboard/',
    icon: <WebIcon />,
  },
  {
    title: 'CloudFlare',
    description: 'Cloudflare is providing content-delivery-network services, DDoS mitigation, Internet security, and distributed domain-name-server services.',
    url: 'https://dash.cloudflare.com/',
    icon: <WebIcon />,
  },
  {
    title: 'Microsoft Azure',
    description: 'Microsoft Azure is a cloud computing service created by Microsoft for building, testing, deploying, and managing applications and services.',
    url: 'https://portal.azure.com/',
    icon: <StorageIcon />,
  },
  {
    title: 'Grafana',
    description: 'Grafana is a multi-platform open-source analytics and interactive visualization web application.',
    url: 'https://grafana.com/',
    icon: <ShowChartIcon />,
  },
  {
    title: 'ArgoCD',
    description: 'ArgoCD is a declarative, GitOps continuous delivery tool for Kubernetes.',
    url: 'https://argoproj.github.io/argo-cd/',
    icon: <SyncIcon />,
  },
  {
    title: 'Kubernetes',
    description: 'Kubernetes is an open-source container-orchestration system for automating computer application deployment, scaling, and management.',
    url: 'https://kubernetes.io/',
    icon: <ContainerIcon />,
  },
];

const UsefulLinksCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title}>Useful Links</Typography>
        <Grid container spacing={2}>
          {links.map(link => (
            <Grid item xs={12} sm={6} md={4} key={link.title}>
              <Grid container alignItems="center">
                <Grid item className={classes.icon}>
                  {link.icon}
                </Grid>
                <Grid item>
                  <Link to={link.url} target="_blank" rel="noopener noreferrer" className={classes.link}>
                    <Typography variant="body1">{link.title}</Typography>
                  </Link>
                  <Typography variant="body2" color="textSecondary">
                    {link.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UsefulLinksCard;