import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  storageApiRef,
  identityApiRef,
  discoveryApiRef
} from '@backstage/core-plugin-api';
import {
  visitsApiRef,
  VisitsStorageApi
} from '@backstage/plugin-home';
import { jsonRulesEngineCheckResultRenderer, TechInsightsClient, techInsightsApiRef } from '@backstage-community/plugin-tech-insights';

export const apis: AnyApiFactory[] = [
  createApiFactory({
      api: scmIntegrationsApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
    }
  ),
  ScmAuth.createDefaultApiFactory(),

  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),
  
  createApiFactory({
    api: techInsightsApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new TechInsightsClient({
        discoveryApi,
        identityApi,
        renderers: [
          jsonRulesEngineCheckResultRenderer, // default json-rules-engine renderer
        ],
      }),
  }),
];
