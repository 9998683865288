import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade, Typography, Button, makeStyles, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '700px',
    width: '90%', // Ensure the modal fits within the screen width
    maxHeight: '90vh', // Ensure the modal fits within the screen height
    overflowY: 'auto', // Enable scrolling for overflow content
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  leftAlign: {
    textAlign: 'left',
  },
  title: { // New style for additional spacing below title
    marginBottom: theme.spacing(2), // Adjust the value for desired spacing
  },
  changelogLink: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    fontSize: '0.875rem', // Smaller font size for the link
  },
}));

const CHANGELOG_VERSION = '1.18.0'; // Update this version when there are new changes

const ChangelogPopup = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const seenVersion = localStorage.getItem('changelogVersion');
    if (seenVersion !== CHANGELOG_VERSION) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('changelogVersion', CHANGELOG_VERSION);
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="changelog-modal-title"
      aria-describedby="changelog-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography variant="h4" id="changelog-modal-title" className={classes.title}>
            What's New in CST-IDP
          </Typography>
          <Typography variant="body1" id="changelog-modal-description" paragraph className={classes.leftAlign}>
            Welcome to the latest version <strong>1.18</strong> of our Internal Developer Platform! Here are the new features and improvements:
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            <strong>## [1.18] - 2025-01-20:</strong>
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #1: We created new <strong>Frontend plugin for Code Release</strong>. This will allow you seamlessly release your code to different environments (Plugin). 
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            <strong>## [1.17] - 2025-01-01:</strong>
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #1: We created new <strong>Frontend plugin Knauf-ACR for Azure Container Registry</strong> in IDP. You will now be able to see your ACR repositories in the IDP UI (Plugin).
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #2: We created new <strong>Scaffolder plugin Knauf-Shell </strong> which will allow to create templates with Bash and Node (JS) scripts. This plugin also has integration with Azure Key Vault so that we can fetch secrets like in GitHub actions (Templates).
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #3: We created new <strong>Scaffolder plugin Knauf-Upload-File </strong> that will allow to upload file through form in templates. Use case for this is for example to deploy automation using Excel file with data as inputs (Templates).
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #4: We added <strong>ArgoCD plugin</strong> in IDP. You will now be able to see your ArgoCD applications in the IDP UI (Plugin).
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #5: We added <strong>Tech-Insight (Scorecards) plugin</strong> in IDP. With this plugin you can check the quality of your application. Currently we just have few rules but we will develop new according to KD standards (Plugin).
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #6: We added <strong>Notifications</strong> in IDP. This can be used to send notifications between any entities, apps to apps, users to users, apps to users, users to apps. For example when you deploy your application you can send notification to your team (Core).
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Improvement #1: Updated the <strong>Landing page</strong> with new links and resources, and also updated the <strong>Knauf Digital Theme</strong>.
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Improvement #2: Previously, when you triggered GitHub Actions using IDP UI templates, you had to go to GitHub Actions to view the results. Now, the results are returned directly within the IDP with success or fail message (Templates).
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Improvement #3: <strong>CST-IDP</strong> platform updated to latest version <strong>1.34.2</strong> (Backstage).
          </Typography>
          <Typography className={classes.changelogLink}>
            <Link href="https://github.com/Knauf-Group/cst-knauf-idp/blob/main/CHANGELOG.md" target="_blank" rel="noopener">
            - View Full Changelog
            </Link>
          </Typography>
          <Button variant="contained" color="primary" onClick={handleClose} className={classes.button}>
            Got it!
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

export default ChangelogPopup;