import React from 'react';
import { InfoCard, CodeSnippet } from '@backstage/core-components';
import { Typography, makeStyles } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';

type CustomMissingAnnotationProps = {
  annotations: string[];
};

const useStyles = makeStyles({
  text: {
    fontSize: '1.1rem', // Adjust the font size as needed
  },
});

export const CustomMissingAnnotation = ({ annotations }: CustomMissingAnnotationProps) => {
  const classes = useStyles();
  const { entity } = useEntity();
  const entityName = entity.metadata.name;
  const entityType = entity.spec?.type || 'service';
  const entityOwner = entity.spec?.owner || 'group:default/devops';

  // const annotationList = annotations.map(annotation => `    ${annotation}: value`).join('\n');
  const annotationList = annotations.map(annotation => {
    // Check if the annotation is the 'azure-container-registry/names'
    if (annotation === 'azure-container-registry/names') {
      // Use a list of values for this annotation to demonstrate multiple values
      return `    ${annotation}: value1,value2`;
    }
  
    // Default to a single value for other annotations
    return `    ${annotation}: value`;
  }).join('\n');

  const exampleYaml = `apiVersion: backstage.io/v1alpha1
kind: Component
metadata:
  name: ${entityName}
  annotations:
${annotationList}
spec:
  type: ${entityType}
  owner: ${entityOwner}`;

  return (
    <InfoCard title="Missing Annotation">
      <Typography className={classes.text}>
        The annotation {annotations.join(', ')} is missing. You need to add the annotation to your Component if you want to enable this tool.
      </Typography>
      <Typography className={classes.text}>
        Add the annotation to your Component YAML as shown in the highlighted example below:
      </Typography>
      <div style={{ fontSize: '0.90rem' }}>
        <CodeSnippet language="yaml" text={exampleYaml} />
      </div>
    </InfoCard>
  );
};