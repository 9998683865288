import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { TagsResponse } from '../types';

export interface AzureContainerRegistryApiV1 {
  getTags(repo: string, entity: Entity): Promise<TagsResponse[]>;
}

export const AzureContainerRegistryApiRef =
  createApiRef<AzureContainerRegistryApiV1>({
    id: 'plugin.acr.service',
  });

export type Options = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

export class AzureContainerRegistryApiClient
  implements AzureContainerRegistryApiV1
{
  private readonly discoveryApi: DiscoveryApi;
  // private readonly configApi: ConfigApi;
  private readonly identityApi: IdentityApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    // this.configApi = options.configApi;
    this.identityApi = options.identityApi;
  }

  private async getBaseUrls(entity: Entity) {
    const registryNames = entity.metadata.annotations?.['azure-container-registry/names'];
    const proxyPaths = registryNames ? registryNames.split(',').map(name => `/acr/${name}/api`) : [];
    return Promise.all(proxyPaths.map(async path => `${await this.discoveryApi.getBaseUrl('proxy')}${path}`));
  }

  private extractRegistryName(url: string): string {
    const match = url.match(/\/acr\/([^\/]+)\/api/);
    return match ? match[1] : 'unknown';
  }

  private async fetcher(url: string, registry: string, queryParams: Record<string, string> = {}) {
    const { token: idToken } = await this.identityApi.getCredentials();
    const queryString = new URLSearchParams(queryParams).toString();
    const fullUrl = queryString ? `${url}?${queryString}` : url;

    const response = await fetch(fullUrl, {
      headers: {
        'Content-Type': 'application/json',
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
      },
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error(
        `failed to fetch data from registry ${registry}, status ${response.status}: ${response.statusText}`,
      );
    }
    const data = await response.json();
    return { ...data, registry }; // Include the registry name in the response
  }

  async getTags(repo: string, entity: Entity) {
    const proxyUrls = await this.getBaseUrls(entity);
    const responses = await Promise.all(
      proxyUrls.map(async url => {
        const registryName = this.extractRegistryName(url);
        try {
          // Fetch all tags with a large `n` parameter to ensure all tags are retrieved
          return await this.fetcher(`${url}/${repo}/_tags`, registryName, { n: '10000' });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Error fetching data from registry ${registryName}:`, error);
          return null; // Return null for failed requests
        }
      })
    );
    return responses.filter(response => response !== null) as TagsResponse[];
  }
}